<template>
  <div>
    <div class="header">
      <v-img lazy-src="@/assets/images/news-homepage1.png" max-height="3.46rem" max-width="100%" eager
        src="@/assets/images/news-homepage1.png">
        <!-- <template>
          <div class="header-text">新闻资讯</div>
        </template> -->
      </v-img>
    </div>
    <div class="content">
      <v-container>
        <h3 class="title">{{ newObj.newsTitle }}</h3>
        <div style="display: flex;justify-content: space-between;">
          <p class="grey--text time">来源：微观纪元</p>
          <p class="grey--text form">{{ newObj.createTime }}</p>
        </div>
        <v-divider></v-divider>
        <div class="ql-editor newsContent " v-html="newObj.newsContent"></div>
        <div class="type" v-if="newObj.newsType">
          <span class="text">{{ newObj.newsTypeName }}</span>
        </div>
      </v-container>

    </div>
  </div>
</template>
<script>
import { getNewsDetails } from "@/api/index"
export default {
  name: "NewsContent",
  components: {},
  beforeCreate() { },
  created() {
    this.getDetails(this.$route.params.id)
  },
  beforeDestroy() { },
  filters: {},
  data() {
    return {
      page: 1,
      newObj: ''
    };
  },
  methods: {
    async getDetails(id) {
      let res = await getNewsDetails(id);
      if (res.code == 200) {
        this.newObj = res.data;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import url('./quill.snow.css');

.header {
  width: 100%;

  .header-text {
    text-align: center;
    color: #fff;
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 3.46rem;
    -webkit-background-clip: text;
  }
}

.car-img {
  border-radius: 0.12rem;
  box-shadow: 0px 0.03rem 0.06rem 0.01rem rgba(0, 0, 0, 0.16);
}

.content {
  padding-top: 0.34rem;
  background: #FFFFFF;
  box-shadow: inset 0px -0.03rem 0.06rem 0.01rem rgba(0, 0, 0, 0.16);

  .title {
    font-size: 0.3rem !important;
    margin-bottom: 0.2rem;
    text-align: center;

  }

  .time {
    font-size: 0.16rem;
  }

  .form {
    font-size: 0.16rem;
  }

  .newsContent {
    width: 80%;
    margin: 0.3rem auto;
    font-size: 16px;
    line-height: normal !important;

    ::v-deep p {
      margin: 0 !important;
      line-height: 20px !important;
      font-family: "SourceHanSansCN", Roboto, Arial, "Helvetica Neue", sans-serif !important;
    }

    ::v-deep img {
      width: 60% !important;
      margin: 0 auto !important;
      text-align: center;
      display: block;
    }
  }

}

.footer {
  width: 60%;
  margin: 0.3rem auto;
  height: 0.8rem;
}

.pagination {
  float: right;
}

.type {
  font-size: 0.2rem;
  font-weight: 400;

  .text {
    padding: 0.1rem 0.2rem;
    border-radius: 0.2rem;
    background: #E0E1F1;
  }
}

.container {
  padding-bottom: 1rem;
}</style>
